import { useState, useEffect } from "react"

const useSlides = (slides: any[]) => {
  const [index, setIndex] = useState(0)
  const [clicked, setClicked] = useState(false)

  const handleChange = (idx: number) => {
    setClicked(true)
    setIndex(idx)
  }

  useEffect(() => {
    if (clicked) {
      return
    }

    const next = () => {
      setIndex(index < slides.length - 1 ? index + 1 : 0)
    }

    if (process.env.NODE_ENV === "development") {
      // no auto sliding in dev, it's just annoying me
      return
    }

    const timeout = setTimeout(next, 5000)

    return () => {
      clearTimeout(timeout)
    }
  }, [slides.length, clicked, index])

  return {
    index,
    handleChange,
  }
}

export default useSlides
