import React from "react"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image/withIEPolyfill"
import styled from "styled-components"
import SwipeableViews from "react-swipeable-views"

import { Message } from "../components/l10n"
import SEO from "../layout/seo"
import useSlides from "../helpers/useSlides"

const Container = styled.div`
  position: relative;
`
const Slides = styled(SwipeableViews)`
  .gatsby-image-wrapper {
    height: 100vh;
  }
`
const Badge = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  width: 460px;
  height: 460px;
  border-radius: 230px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  box-sizing: border-box;
  padding: 30px;
  h1 {
    color: #ff73f4;
    font-weight: bold;
    font-size: 64px;
    margin: 60px 0 25px;
  }
  p {
    font-size: 18px;
    line-height: 22px;
    margin: 10px 0;
  }
  a {
    margin-top: 25px;
    background-color: #ff73f4;
    padding: 15px 25px;
    border-radius: 30px;
    display: inline-block;
    font-size: 26px;
  }
  @media (max-width: 515px) {
    width: 260px;
    height: 260px;
    padding: 15px;
    h1 {
      font-size: 32px;
      margin: 18px 0 10px;
    }
    p {
      font-size: 14px;
      line-height: 15px;
      margin: 5px 0;
    }
    a {
      margin-top: 10px;
      padding: 10px 20px;
      font-size: 18px;
    }
  }
`
const Dots = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  button {
    margin: 5px;
    width: 15px;
    height: 15px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    &.current {
      background-color: rgba(255, 255, 255, 0.7);
    }
  }

  @media (max-width: 400px) {
    button {
      margin: 3px;
      width: 13px;
      height: 13px;
    }
  }
`

const HomePage = ({ data }) => {
  const slides = data.slides.nodes

  const { index, handleChange } = useSlides(slides)

  return (
    <Container>
      <SEO />
      <Slides index={index} onChangeIndex={handleChange}>
        {slides.map((node, key) => (
          <Image
            key={key}
            fluid={node.childImageSharp.fluid}
            style={{ height: "100h" }}
            objectPosition="50% 80%"
            loading={key <= index + 1 ? "eager" : "lazy"}
          />
        ))}
      </Slides>
      <Badge>
        <Message id="why" Tag="h1" />
        <Message id="reason1" Tag="p" />
        <Message id="reason2" Tag="p" />
        <Message id="reason3" Tag="p" />
        <Link to="/shop/">Shop</Link>
      </Badge>
      <Dots>
        {slides.map((_, key) => (
          <button className={key === index ? "current" : null} onClick={() => handleChange(key)} />
        ))}
      </Dots>
    </Container>
  )
}

export default HomePage

export const query = graphql`
  query {
    slides: allFile(filter: { relativeDirectory: { eq: "slides" } }, sort: { fields: base }) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
